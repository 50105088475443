<template>
  <div class="main">
    <div class="wrapper pt-4">
      <div class="surface bg-white shadow d-flex flex-column px-4 pt-5 pb-4 mt-4 w-100 top">
        <h2 class="mb-5 text-center text-sm-left">Log in to SureFooted</h2>
        <form class="d-flex flex-column" @submit.prevent="submit" novalidate>
          <div class="form-group">
            <label class="auth-labels text-uppercase small pl-1" for="email">Email address*</label>
            <div :class="{ error: v$.email.$errors.length }">
              <input type="email" class="form-control" name="email" v-model="v$.email.$model" @input="v$.email.$touch" @keyup="formCheck">
              <div v-if="responseError" class="error-msg">{{ responseMessage }}</div>
              <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="form-group mt-1">
            <div class="auth-labels d-flex align-items-center px-1">
              <label class="text-uppercase small" for="password">Password*</label>
              <router-link class="small text-primary ml-auto mb-2" :to="{ name: 'forgot-password', params: {} }">Forgot password?</router-link>
            </div>
            <div :class="{ error: v$.password.$errors.length }">
              <input type="password" class="form-control" name="password" v-model="v$.password.$model" @input="v$.password.$touch" @keyup="formCheck">
              <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary ml-auto mt-3" :disabled="disabledButton" @click.prevent="login">Log in</button>
        </form>
      </div>
      <div class="surface d-flex align-items-center justify-content-center mt-3">
        <p class="font-weight-normal mb-0 mr-2">Don't have an account?</p>
        <router-link class="text-primary font-weight-bold ml-2" :to="{ name: 'signup', params: {} }">Sign up</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, maxLength, helpers } from '@vuelidate/validators'
import eventHub from '../utils/eventHub'

export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      disabledButton: true,
      responseError: false,
      responseMessage: ''
    }
  },
  validations () {
    return {
      email: {
        required: helpers.withMessage('Email is required.', required),
        email: helpers.withMessage('This email format is not valid.', email),
        maxLength: maxLength(100)
      },
      password: {
        required
      }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validationConfig: { $autoDirty: true },
  methods: {
    formCheck () {
      var formErrors = document.getElementsByClassName('error-msg')
      if (formErrors.length > 0 || this.email === '' || this.password === '') {
        this.disabledButton = true
      } else {
        this.disabledButton = false
      }

      this.responseError = false
      this.responseMessage = ''
    },
    async login (evt) {
      var data = new FormData()
      data.append('email', this.email)
      data.append('password', this.password)
      this.axios
        .post(process.env.VUE_APP_API + '/session/signin', data)
        .then(async (response) => {
          this.axios.defaults.headers.common.authorization = `Bearer ${response?.data?.data?.access_token}`

          eventHub.$emit('login', {
            user: response?.data?.data?.user,
            access_token: response?.data?.data?.access_token
          })
          const { id } = response?.data?.data?.user || {}
          const resp = await this.axios.get(process.env.VUE_APP_API + `/profile/get/${id}`)

          if (resp?.data?.data) {
            eventHub.$emit('user-data', {
              user: resp.data.data
            })
          }
          this.$router.push({ path: '/dashboard', params: {} })
        }).catch((error) => {
          var key = Object.keys(error.response.data)[0]
          this.responseError = true
          if (error.response.data[key].includes('Error processing session.signin: Authentication error - bad credentials')) {
            this.responseMessage = 'Your credentials are not valid.'
          } else {
            this.responseMessage = error.response.data[key]
          }
        })
    }
  }
}
</script>
